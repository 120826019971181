import Layout from "./common/layout"
import Marketplace from "./channels/Marketplace/Marketplace"
import Head from "./common/Head"
import { useFormatter } from "helpers/i18n"

const ChannelsMarketplace = () => {
  const { currentLocale } = useFormatter()

  return (
    <>
      <Head
        showCaptcha={true}
        title={
          currentLocale === "en"
            ? "Vietnam's number one marketplace platforms partner"
            : "Đối tác số 1 của các sàn thương mại điện tử tại Việt Nam"
        }
        description={
          currentLocale === "en"
            ? "OnPoint is Vietnam's number one marketplace platforms partner. We are the only e-commerce enabler in Vietnam achieved the highest ranking from Lazada & Shopee."
            : "OnPoint là đối tác số 1 của các sàn TMĐT tại Việt Nam. Đơn vị cung cấp các dịch vụ hỗ trợ TMĐT duy nhất đạt được đồng thời thứ hạng cao nhất ở Lazada và Shopee."
        }
        image={
          "https://s3.ap-southeast-1.amazonaws.com/public.onpoint.vn/channel-1-meta.png"
        }
      />
      <Layout>
        <Marketplace />
      </Layout>
    </>
  )
}

export default ChannelsMarketplace
