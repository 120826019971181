import BannerHeader from "pages/common/BannerHeader"
import GetStart from "pages/common/GetStart"
import LogoFooter from "pages/service/Common/LogoFooter"
import SiderNew from "pages/channels/Marketplace/components/SiderNew"

import logo1 from "images/Service/Store/logo-1.png"
import logo2 from "images/Service/Store/logo-2.png"
import logo3 from "images/Service/Store/logo-3.png"
import logo4 from "images/Service/Store/logo-4.png"
import logo5 from "images/Service/Store/logo-5.png"
import { StaticImage } from "gatsby-plugin-image"
import { useFormatter } from "helpers/i18n"

export default function Marketplace() {
  const { __ } = useFormatter()
  const banner = "../../../images/Channels/Marketplace/banner-1.png"
  const mobileBanner =
    "../../../images/Channels/Marketplace/marketplace_banner_mobile.png"
  return (
    <>
      <BannerHeader
        breadcrumb={[
          {
            label: `${__({ defaultMessage: "Home/" })}`,
            url: "/",
          },
          {
            label: `${__({ defaultMessage: "Channels/" })}`,
          },
          {
            label: `${__({ defaultMessage: "Marketplace" })}`,
          },
        ]}
        banner={
          <StaticImage
            layout="fullWidth"
            src={banner}
            alt=""
            decoding="async"
            fetchpriority="high"
            quality={100}
          />
        }
        mobileBanner={
          <StaticImage
            layout="fullWidth"
            src={mobileBanner}
            alt=""
            decoding="async"
            fetchpriority="high"
            quality={100}
          />
        }
        logo={""}
        listTitle={[`${__({ defaultMessage: "Marketplace" })}`]}
        description={
          <p>
            {__({
              defaultMessage: "Vietnam's No.1 E-commerce Platforms Partner",
            })}
          </p>
        }
      />
      {[
        <SiderNew />,
        <LogoFooter
          title={__({
            defaultMessage: "Marketplace Platform Partners",
          })}
          description={__({
            defaultMessage:
              "We help you tap into the growth potential of the leading marketplace platforms.",
          })}
          list={[logo2, logo1, logo3, logo4, logo5]}
        />,
      ].map((component, key) => (
        <section key={key} className={key > 0 ? "section" : null}>
          <div className="reveal">{component}</div>
        </section>
      ))}
      <GetStart />
    </>
  )
}
