import ContainerMain from "pages/common/ContainerMain"

import icon1 from "images/Channels/Marketplace/icon-1.svg"
import image1 from "images/Home/Slider/image-1.png"
import image3 from "images/Home/Slider/image-3.png"
import image4 from "images/Home/Slider/image-4.png"

import new7 from "images/Channels/Marketplace/new-7.png"
import new8 from "images/Channels/Marketplace/new-8.png"
import new9 from "images/Channels/Marketplace/new-9.png"
import new10 from "images/Channels/Marketplace/new-10.png"
import "./SiderNew.scss"
import Slider from "react-slick/lib/slider"
import prevSliderIcon from "images/prev_slider_incon_gray.svg"
import nextSliderIcon from "images/next_slider_incon_gray.svg"
import { useFormatter } from "helpers/i18n"
import React from "react"

export default function SiderNew() {
  const { __ } = useFormatter()
  const news = [new7, new8, new9, new10]

  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const imgList = [image3, image4, image1]
  return (
    <>
      <div className="kcnwublglj">
        <ContainerMain>
          <div className="vytblxrlvi">
            <div className="marketplace-award">
              <div className="marketplace-award-wrapper">
                <div className="eztyqrklok">
                  <img src={icon1} alt="" className="qunzwtnhcl" />
                </div>
                <div className="petlajucug">
                  {__({
                    defaultMessage:
                      "We are the only e-commerce enabler in Vietnam achieved the highest ranking from Lazada & Shopee",
                  })}
                </div>
                <div className="wplkldfiqp">
                  <div className="market-place-award">
                    {imgList.map((img, i) => {
                      return (
                        <React.Fragment key={i}>
                          <img src={img} alt="" />
                        </React.Fragment>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
            <Slider {...settings}>
              {news.map((item, index) => {
                return (
                  <div className="xrnlsodhaw" key={index}>
                    <img src={item} alt="" />
                  </div>
                )
              })}
            </Slider>
          </div>
        </ContainerMain>
      </div>
    </>
  )
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <img
      src={nextSliderIcon}
      className={className}
      style={{ ...style, display: "block", color: "#777777", height: 15 }}
      onClick={onClick}
    />
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <img
      src={prevSliderIcon}
      className={className}
      style={{ ...style, display: "block", color: "#777777", height: 15 }}
      onClick={onClick}
    />
  )
}
